import { TOrderKeys } from "@store/slice/order.slice";
import { useRedStore } from "@store/store";
import { overrideDefaults, updateCase } from "api/service/case";
import { useMutation, useQuery } from "react-query";
import debounce from "lodash.debounce";
import toast from "react-hot-toast";
import { queryClient } from "scripts/reactQuery";
import useOrderAction from "@store/action-hook/useOrderAction";
import { convertTo, objectToQueryStringOmitEmpty, payloadValidationCheck, showErrorToast, showSuccessToast } from "@utils";
import { collectCash, getPaymentQRStatus, getPosPaymentStatus, getQrCode, orderPaymentInfo, paymentPos, sendSMSService } from "api/service/payment";
import { version } from "../../../package.json";
import usePaymentAction from "@store/action-hook/usePaymentAction";
import { useGetCase } from "@hooks/api/useCaseApi";
import { useEffect, useState } from "react";
import useSyncMutation from "@hooks/action/useRedMutation";
import { useOrder } from "@hooks/api/useSave";
import { PLATFORM_OBJ } from "@config/constant";
import { Navigate, useNavigate, useParams } from "react-router-dom";

interface IMutationOptions {
  onSuccess?: (data?: any) => any;
  isSaathiFleet?: boolean;
}

const usePayment = () => {
  const collectPayment = useRedStore(state => state.collectPayment);
  const order = useRedStore(state => state.order);
  const user = useRedStore(state => state.user);
  const { convertToBooking, ...rest } = useOrder();

  useEffect(() => {}, [user]);

  const { mutateAsync: collectCashAsync, ...collectCashAsyncState } = useSyncMutation(collectCash);
  // http://localhost:6200/case/RED_R0MLPO8R/payment
  const collectCashAsyncDebounce = debounce(collectCashAsync, 250);

  const collectCashFn = async ({ isSaathiFleet, onSuccess }: IMutationOptions) => {
    const payload: any = {
      orderId: order?.orderId,
      cashCollected: collectPayment?.amount * 100,
      // TODO: NEED TO ACTIVE THIS CODE.
      // type: "Cash Payment",
      type: "HM-APP",
      comment: `HM App - <v.${version}>`,
      userId: user?.userId,
      geoTagging: {
        lat: 0,
        long: 0,
      },
      platform: PLATFORM_OBJ.platform,
      currency: "INR",
    };

    if (isSaathiFleet) {
      payload.tag = "SAATHI";
    }

    if (!Object.values(payload).every(v => !!v)) {
      showErrorToast("Please fill all the required fields");
      return;
    }

    await collectCashAsyncDebounce(payload, {
      onSuccess: (data: any) => {
        // showSuccessToast("Payment Collected Successfully.");
        if (order?.bookingType !== "BOOKING") {
          convertToBooking({
            onSuccess: () => {
              onSuccess && onSuccess(data);
              showSuccessToast("Payment Collected Successfully.");
              // showSuccessToast("Covert to Booking successfully.");
            },
          });
        } else {
          onSuccess && onSuccess(data);
        }
      },
    });
  };

  return { collectCashFn, collectCashAsyncState: { isLoading: collectCashAsyncState?.isLoading || rest.isLoading } };
};

const useSms = ({ orderId, amount, isSaathiFleet }: { orderId: string; amount: any; isSaathiFleet?: boolean }) => {
  const { mutateAsync: generateQe, ...smsStatus } = useMutation(sendSMSService);

  const debounceAsync = debounce(generateQe, 250);

  if (amount == 0) {
    showErrorToast("Please provide valid amount.");
    throw Error("Invalid amount provide.");
  }

  const payload: any = { orderId: orderId, amount: parseInt(amount), phoneNumber: "", geoTagging: { lat: 0, long: 0 }, currency: "INR", platform: PLATFORM_OBJ.platform };

  if (isSaathiFleet) {
    payload.tag = "SAATHI";
  }

  const sendSms = (mobile: string, cb?: any) => {
    payload.phoneNumber = mobile;
    payloadValidationCheck(payload, () =>
      debounceAsync(payload, {
        onSuccess: () => {
          showSuccessToast("SMS send successfully.");
          cb && cb();
        },
      })
    );
  };

  return { sendSms, smsStatus };
};

const usePaymentInfo = ({ orderId, onSuccess, onError }: { orderId?: string; onSuccess?: any; onError?: any }) => {
  const { initiatePaymentModule } = usePaymentAction();

  const caseData = useGetCase({ orderId });

  const payload: any = {
    orderId: orderId,
  };

  const orderPaymentData = useQuery([`order-pricing-id-${orderId}`], () => orderPaymentInfo(objectToQueryStringOmitEmpty(payload)), {
    enabled: !!orderId,
    refetchOnWindowFocus: true,
    cacheTime: 0,
    onSuccess: (data: any) => {
      onSuccess && onSuccess(data);
    },
    onError: (error: any) => {
      onError && onError(error);
    },
  });

  useEffect(() => {
    const _caseData = caseData?.data?.data?.data;
    const _orderPaymentData: any = orderPaymentData?.data?.data;

    if (_caseData && _orderPaymentData) {
      const payload = {
        city: _caseData.city,
        ..._orderPaymentData,
      };

      initiatePaymentModule(payload);
    }
  }, [caseData?.isFetching, orderPaymentData?.isFetching]);

  return { isLoading: orderPaymentData?.isLoading || caseData?.isLoading || orderPaymentData?.isFetching || caseData?.isFetching };
};

const useOnlinePayment = ({ onSuccess }: IMutationOptions) => {
  const { mutateAsync: generateQe, ...qrStatus } = useMutation(getQrCode);
  const { convertToBooking } = useOrder();
  const debounceAsync = debounce(generateQe, 250);
  const navigate = useNavigate();
  const { id: orderId } = useParams();

  const [qrData, setQrData] = useState<any>({
    qrExpiryTimeInSecs: 0,
    qrId: "",
    qrImageLink: "",
    isBookClosed: false,
  });

  useQuery([`payment-token-status`], () => getPaymentQRStatus(objectToQueryStringOmitEmpty({ qrId: qrData?.qrId, orderId: orderId || "-" })), {
    enabled: !!qrData?.qrId,
    refetchOnWindowFocus: true,
    refetchInterval: 5000,
    onSuccess: (data: any) => {
      // onSuccess && onSuccess(data);
      if (data?.data?.status !== "PENDING") {
        showSuccessToast("Payment Complete");
        convertToBooking({
          onSuccess(data) {
            const orderId = data?.data?.data?.orderId;
            if (orderId) {
              showSuccessToast("Convert it to Booking.");
              navigate(`/case-history/${orderId}/case-overview`);
            } else {
              showErrorToast("No Order Id Found.");
              navigate(`/`);
            }
          },
        });
      }
    },
    onError: (error: any) => {
      // onError && onError(error);
      showErrorToast("Payment Collection Failed.");
    },
  });

  const getQr = (orderId: string, amount: number, isSaathiFleet?: boolean) => {
    const qrPayload: any = {
      orderId: orderId,
      amount: amount,
      geoTagging: {
        lat: 0,
        long: 0,
      },
      currency: "INR",
      platform: PLATFORM_OBJ.platform,
      meta: {
        paymentType: "ADVANCE",
      },
    };

    if (isSaathiFleet) {
      qrPayload.tag = "SAATHI";
    }

    debounceAsync(qrPayload, {
      onSuccess: (data: any) => {
        const response = data?.data;
        if (response) {
          if (response?.status === "BOOK_CLOSED") {
            setQrData({
              qrExpiryTimeInSecs: 0,
              qrId: "",
              qrImageLink: "",
              isBookClosed: true,
            });
            return;
          } else if (Object?.values(response)?.length > 0) {
            setQrData({ ...response, isBookClosed: false });
          }
        }

        onSuccess && onSuccess(data);
      },
    });
  };

  return { getQr, qrStatus, qrData };
};

interface IPosPayment {
  paymentMode: string;
  amount?: number;
}

const usePosPayment = ({ orderId, sourceId, onError, userId }: { orderId: string; sourceId: string; onError: (err: any) => void; userId: string }) => {
  const { mutateAsync: generateQe, ...posPaymentStatus } = useMutation(paymentPos);

  const debounceAsync = debounce(generateQe, 250);

  // TODO: source
  const payload: any = { orderId: orderId, paymentMode: "CARD", sourceId: sourceId, userId: userId };

  const posPayment = ({ amount, paymentMode }: IPosPayment, cb?: any) => {
    payload.paymentMode = paymentMode;

    if (amount !== undefined && !isNaN(+amount)) {
      payload.amount = +amount;
    }

    debounceAsync(payload, {
      onSuccess: () => {
        // showSuccessToast("POS Payment initiated.");
        cb && cb();
      },
      onError: (err: any) => onError(err),
    });
  };

  return { posPayment, posPaymentStatus };
};

const usePosPaymentStatus = ({ orderId, sourceId }: { orderId: string; sourceId: string }) => {
  const response = useQuery([`pos-payment-status-${orderId}`], () => getPosPaymentStatus(objectToQueryStringOmitEmpty({ orderId, sourceId })), {
    enabled: !!orderId,
    select: (data: any) => data?.data?.data,
  });
  return response;
};

export { usePayment, usePaymentInfo, useOnlinePayment, useSms, usePosPayment, usePosPaymentStatus };
