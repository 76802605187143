import request from "../request";

const urls = {
  collectCashURL: "/payments/collectCash",
  orderDetailsURL: "/payments/orderDetails",
  getQrCodeURL: "/payments/generateQR",
  sendSmsURL: "/payments/sendPaymentLink",
  paymentStatus: `/payments/status`,
  posPayment: "/payments/pos",
  posPaymentStatus: "/payments/pos/latest",
};

export const collectCash = (payload: any) => {
  const response = request("POST", urls.collectCashURL, payload);
  return response;
};

export const orderPaymentInfo = (query?: string) => {
  const response = request("GET", `${urls.orderDetailsURL}${query}`);
  return response;
};

export const getQrCode = (payload: any) => {
  const response = request("POST", urls.getQrCodeURL, payload);
  return response;
};

export const sendSMSService = (payload: any) => {
  const response = request("POST", urls.sendSmsURL, payload);
  return response;
};

export const getPaymentQRStatus = (query: string) => {
  const response = request("GET", `${urls.paymentStatus}${query}`);
  return response;
};

export const paymentPos = (payload: any) => {
  const response = request("POST", urls.posPayment, payload);
  return response;
};

export const getPosPaymentStatus = (query: string) => {
  const response = request("GET", `${urls.posPaymentStatus}${query}`);
  return response;
};

// 'http://localhost:3000/payments/pos/latest?orderId=RED_14PS79SZ'
